@import 'assets/style/helper.scss';

.bottom_line {
  border-bottom: 1px solid $black;
}

.acc_btn {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 28px;

  svg {
    margin-left: 6px;
    transition: all 0.3s;
    width: 14px;
    height: 14px;

    path {
      fill: $black;
    }
  }

  &_open {
    svg {
      transform: rotate(-180deg);
    }
  }
}

.space_between {
  width: 100%;
  justify-content: space-between;
}

.content {
  text-align: left;
  width: 100%;
  margin: 6px 0px;
}
