@import 'assets/style/helper.scss';

.modal {
  width: 100% !important;
  height: 100vh !important;
  margin: 0px !important;
  background: linear-gradient(356deg, $blue_aztec -40%, $white 80%) !important;
}

.modal_overlay {
  background-color: rgba($black, 0.6);
}

.modal_closeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  top: 4px !important;
  right: 4px !important;
  transition: background-color 0.3s;

  svg {
    height: 50px;
    width: 50px;

    path {
      fill: rgba($black, 0.6) !important;
    }
  }

  @include hover {
    background-color: rgba($black, 0.3);
  }
}

.menu_wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;
  margin-top: 20px;
}

.nav_item {
  position: relative;
  font-size: 30px;
  line-height: 23px;
  padding: 16px 10px;
  background: linear-gradient(90deg, #101c26 0%, #4b749f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  &::after {
    position: absolute;
    content: '';
    inset: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(75, 116, 159, 0.15) 95.71%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  @include hover {
    &::after {
      opacity: 1;
    }
  }
}
