@import 'assets/style/helper.scss';

.subscribe {
  background: linear-gradient(90deg, #101c26 0%, #4b749f 138.19%);
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.25);
  padding: 32px 0px;
  margin-bottom: 50px;
  text-align: center;
  color: $white_water;
}

.title {
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.text {
  font-size: 14px;
  line-height: 16px;
  max-width: 680px;
  margin: 0px auto 20px;
}

.subscribe_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 20px;

  @include xs {
    flex-wrap: wrap;

    & > * {
      width: 100%;
    }
  }
}
