@import 'assets/style/helper.scss';

.faq_wrapper {
  max-width: 900px;
  margin: 0px auto 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.faq_content {
  font-size: 16px;
  line-height: 22px;
}
