//colors
$black: #000000;
$white: #ffffff;
$white_lilac: #f6f8fc;
$white_water: #f2f6fc;
$white_catskill: #edf0f7;
$blue_wood: #4b749f;
$wedgeWood: #507ba5;
$blue_aztec: #101c26;
$red: #d10000;
$red_pink: #f61f74;
$blueGradLoft: linear-gradient(45deg, rgba(80, 123, 165, 1) 0%, rgba(136, 171, 207, 1) 100%);

//mixins
@mixin xs {
  @media (max-width: 450px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 600px) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin pc {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin lg {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin hover {
  &:hover,
  &:focus-visible {
    @content;
  }
}

@mixin focus {
  &:focus {
    @content;
  }
}

@mixin scrollbar($size: 4px, $thumb_color: $blue_aztec) {
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-track {
    background: $white_lilac;
  }

  &::-webkit-scrollbar-thumb {
    border: 2px solid $thumb_color;
    background-color: $thumb_color;
  }
}

@mixin threeDots {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin threeDotsLine($line: 3) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin blueGradLoftText {
  background-color: $wedgeWood;
  background-clip: text;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  background-image: $blueGradLoft;
}

@mixin accordion($maxHeight: 3000px, $time: 0.7s) {
  overflow: hidden;
  transition: max-height $time cubic-bezier(0, 1, 0, 1);
  max-height: 0px;

  &_open {
    transition: max-height $time cubic-bezier(1, 0, 1, 0);
    max-height: $maxHeight;
  }
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin hoverUnderline($color: $blue_wood, $height: 0.8px) {
  position: relative;

  &::after {
    position: absolute;
    content: '';
    bottom: -0.8px;
    left: 0;
    height: $height;
    width: 0;
    background-color: $color;
    transition: all 0.2s ease-out;
  }

  @include hover {
    &::after {
      width: 100%;
    }
  }
}

// statuses
@mixin status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  max-height: 24px;
  border-radius: 16px;
  font-weight: 600;
  font-size: 11px;
  padding: 4px 8px;
  text-transform: capitalize;
  color: #ad7bbe;
  background-color: rgba(242, 205, 255, 0.6);
}

@mixin status_green {
  color: #458f79;
  background-color: rgba(130, 240, 207, 0.52);
}

@mixin status_purple {
  color: #ad7bbe;
  background-color: rgba(242, 205, 255, 0.6);
}

@mixin status_orange {
  color: #ff9800;
  background-color: rgba(255, 152, 0, 0.2);
}

@mixin status_greenDark {
  color: rgba(53, 95, 81, 0.8);
  background-color: rgba(146, 201, 183, 0.4);
}

@mixin status_yellowDark {
  color: #948929;
  background-color: rgba(241, 223, 56, 0.6);
}

@mixin status_greenDarken {
  color: rgba(53, 95, 81, 0.8);
  background-color: rgba(176, 239, 176, 0.7);
}

@mixin status_red {
  color: rgba(106, 19, 19, 0.8);
  background-color: rgba(235, 136, 136, 0.7);
}

// keyframes
@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out_modal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}
