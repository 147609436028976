@import 'assets/style/helper.scss';

.payment_page {
  width: 100%;
  height: 100vh;
  @include flexCenter;
  background-color: #f1f0ef;
}

.payment_block {
  text-align: center;
  max-width: 350px;
  width: 100%;
  padding: 10px;
}

.amount {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}

.amount_value {
  @include blueGradLoftText;
}

.pay_btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  border-radius: 5px;
  text-transform: capitalize;
  min-width: 160px;
  padding: 10px;
  background: rgb(39, 81, 122);
  background: $blueGradLoft;
  background-size: 101% auto;
  color: $white;
  transition: all 0.5s;

  @include hover {
    box-shadow: inset 0 0 2px 2px rgba(39, 81, 122, 0.3);
  }
}
