@import './helper.scss';

body {
  font-family: 'Numans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  color: $black;
  background-color: $white_lilac;
  @include scrollbar(5px);
}

button,
input,
select,
textarea {
  font-family: 'Numans', sans-serif;
}

// custom style
.main_container {
  max-width: 1444px;
  width: 100%;
  margin: 0px auto;
  padding-left: 24px !important;
  padding-right: 24px !important;

  @include xs {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.title {
  text-align: center;
  font-size: 48px;
  line-height: 56px;
  color: $black;
  margin-bottom: 35px;

  @include tablet {
    font-size: 38px;
    line-height: 48px;
    margin-bottom: 30px;
  }
}

.intro_title {
  max-width: 740px;
  margin: 40px auto 0px;
  padding: 12px 22px;
  font-size: 38px;
  line-height: 38px;
  text-align: center;
  color: $white;
  text-shadow: 0px 4px 4px rgba($black, 0.4);
  background-color: rgba($black, 0.35);
  border-radius: 4px;

  @include sm {
    font-size: 26px;
    line-height: 30px;
  }
}

.scroll_bt30 {
  scroll-margin-top: 30px;
}

.mb26 {
  margin-bottom: 26px;
}

.mb40 {
  margin-bottom: 40px !important;
}

.mb60 {
  margin-bottom: 60px !important;
}

.mb100 {
  margin-bottom: 100px !important;
}

.mt100 {
  margin-top: 100px !important;
}

.tx_a_l {
  text-align: left;
}

.col_blue_wood {
  color: $blue_wood;
}

.acc {
  @include accordion();
}

.burger_btn {
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 26px;
  transition: all 0.5s ease-in-out;

  span {
    width: 100%;
    height: 4px;
    border-radius: 4px;
    background-color: $white_lilac;
    transition: all 0.5s ease-in-out;

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background-color: $white_lilac;
      transition: all 0.5s ease-in-out;
    }

    &::before {
      transform: translateY(-12px);
    }

    &::after {
      transform: translateY(12px);
    }
  }

  &_open {
    span {
      transform: translateX(-40px);
      background: transparent;

      &::before {
        transform: rotate(45deg) translate(30px, -30px);
      }

      &::after {
        transform: rotate(-45deg) translate(30px, 30px);
      }
    }
  }
}

.smooth_enter_an {
  animation: fade-in 0.8s;
}

//page content
.page_content {
  max-width: 1100px;
  margin: 0 auto;

  h3 {
    text-align: center;
    font-size: 27px;
    font-weight: 600;
    line-height: 28px;
    margin: 30px 0px;
    color: $blue_aztec;
  }

  h4 {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    line-height: 25px;
    margin: 24px 0px;
    color: $red_pink;
  }

  h6 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin: 16px 0px;
    color: $red_pink;
  }

  p {
    font-size: 16px;
    line-height: 22px;
    margin: 16px 0px;
  }

  img {
    display: block;
    margin: 16px auto;
    max-width: 800px;
    width: 100%;
    height: auto;
    max-height: 700px;
    object-fit: contain;
  }

  ul {
    margin: 16px 0px;

    li {
      list-style-type: disc;
      list-style-position: inside;
      margin-bottom: 10px;

      &::marker {
        color: $blue_aztec;
      }
    }
  }

  a {
    font-size: 16px;
    line-height: 22px;
    color: $blue_wood !important;

    @include hoverUnderline();
  }
}

//slick slider
.slick-list {
  padding: 8px 0px;
}

.slick-slide {
  & > div {
    margin: 0px 6px;
  }
}

// toast
.Toastify {
  &__toast {
    min-height: 20px !important;
    padding: 7px 6px 9px !important;
    margin-bottom: 5px !important;
  }

  &__toast-body {
    font-family: 'Numans', sans-serif !important;
    color: $white !important;
    font-size: 14px;
    font-weight: 500;
  }

  &__toast-icon {
    svg {
      path {
        fill: $white;
      }
    }
  }

  &__close-button {
    opacity: 0.4 !important;
    color: $white !important;

    @include hover {
      opacity: 1 !important;
    }
  }

  &__toast--info {
    background: linear-gradient(90deg, #254259 0%, #5c8ec3 138.19%) !important;
  }

  &__toast--success {
    background-color: #4caf50 !important;
  }

  &__toast--warning {
    background: linear-gradient(90deg, rgba(242, 126, 59, 1) 0%, rgba(241, 173, 15, 1) 100%) !important;
  }

  &__toast--error {
    background-color: #f44336 !important;
  }

  &__progress-bar {
    bottom: -0.5px;
    background-color: $white !important;
  }
}
