@import 'assets/style/helper.scss';

.modal_wrapper {
  padding: 4px;
  padding-bottom: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 21px;
}

.question {
  font-weight: 500;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 10px;
}

.text {
  font-size: 18px;
  line-height: 26px;
  color: rgba($black, 0.6);
}

.actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  @include sm {
    flex-wrap: wrap;
    gap: 10px;

    button {
      width: 100%;
    }
  }
}
