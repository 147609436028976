@import 'assets/style/helper.scss';

.cart {
  position: relative;
  height: 200px;
  display: flex;
  gap: 20px;
  padding: 10px;
  background-color: $white_water;
  box-shadow: 0px 5px 5px rgba($black, 0.05);
  transition: box-shadow 0.3s;

  @include hover {
    box-shadow: 0px 5px 5px rgba($black, 0.15);
  }

  @include xs {
    gap: 10px;
  }
}

.img_wrapper {
  display: block;
  flex-shrink: 0;
  width: 180px;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include xs {
    width: 125px;
    height: 125px;
  }
}

.info_wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: space-between;
  padding: 14px 0px;
  margin-right: 14px;

  .name {
    font-size: 16px;
    font-weight: 600;
    @include threeDotsLine(2);
  }
}

.count {
  height: 36px;
  max-width: 150px;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: $white;

  &_btn {
    font-size: 24px;
    height: 100%;
    width: 40px;

    @include hover {
      background-color: darken($white_catskill, 5%);
    }
  }

  &_view {
    flex-grow: 1;
    font-size: 18px;
  }
}

.price {
  font-size: 22px;
  line-height: 28px;
  color: $blue_wood;

  &_label {
    color: $black;
    font-size: 18px;
    margin-right: 6px;
  }

  &_old {
    font-size: 18px;
    line-height: 24px;
    color: rgba($black, 0.6);
    text-decoration: line-through;
    margin-right: 4px;
  }
}

.close {
  position: absolute;
  top: 8px;
  right: 8px;

  svg {
    path {
      fill: rgba($black, 0.5);
      transition: fill 0.3s;
    }
  }

  @include hover {
    svg {
      path {
        fill: rgba($black, 1);
      }
    }
  }
}
