@import 'assets/style/helper.scss';

.preset_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 60px;

  @include md {
    gap: 30px;
    flex-wrap: wrap;
  }
}

.images_wrapper {
  width: 40%;

  .main_image {
    flex-shrink: 0;
    display: block;
    margin: 0px auto 20px;
    width: 400px;
    height: auto;
    object-fit: cover;
  }

  .images {
    display: flex;
    flex-direction: column;
    gap: 20px 10px;

    @include md {
      flex-wrap: wrap;
      flex-direction: row;

      & > * {
        flex-basis: 49%;

        @include sm {
          flex-basis: 100%;
        }
      }
    }
  }

  .compare_image_wrapper {
    margin: 0 auto;
    max-width: 400px;
    width: 100%;
    height: auto;
  }

  @include md {
    width: 100%;
    order: 2;
  }
}

.info_wrapper {
  position: sticky;
  top: 16px;
  width: 60%;

  @include md {
    width: 100%;
    position: static;
  }
}

.name {
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 16px;
}

.price {
  font-size: 36px;
  line-height: 42px;
  color: $blue_wood;
  margin-bottom: 20px;
}

.old_price {
  font-size: 24px;
  line-height: 36px;
  color: rgba($black, 0.6);
  text-decoration: line-through;
  margin-right: 10px;
}

.cart_wrapper {
  width: 100%;
  max-width: 400px;

  @include tablet {
    max-width: 100%;
  }
}

.count {
  height: 40px;
  max-width: 150px;
  text-align: center;
  display: flex;
  align-items: center;
  background-color: $white_catskill;
  margin-bottom: 30px;

  &_btn {
    font-size: 28px;
    height: 100%;
    width: 40px;

    @include hover {
      background-color: darken($white_catskill, 5%);
    }
  }

  &_view {
    flex-grow: 1;
    font-size: 20px;
  }
}

.add_cart_btn {
  margin-bottom: 30px;

  button {
    width: 100%;
  }
}

.secure {
  text-align: center;
  margin-bottom: 30px;

  &_label {
    font-size: 13px;
    line-height: 14px;
    margin-bottom: 7px;
  }

  &_wrapper {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
  }
}

.desc {
  p {
    font-size: 17px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  ul {
    margin-bottom: 14px;

    li {
      font-size: 17px;
      list-style-type: disc;
      list-style-position: inside;
      padding-left: 20px;
      margin-bottom: 6px;

      &::marker {
        color: $blue_aztec;
      }
    }
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
    line-height: 25px;
    margin: 20px 0px;
  }

  h3 {
    font-size: 21px;
    line-height: 23px;
    margin: 20px 0px;
  }
}
