@import 'assets/style/helper.scss';

.shop_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 20px;
  margin-bottom: 50px;

  @include md {
    grid-template-columns: repeat(2, 1fr);
  }

  @include sm {
    grid-template-columns: repeat(1, 1fr);
  }
}

.pagination {
  margin-bottom: 100px;
}
