@import 'assets/style/helper.scss';

.sellers_wrapper {
  display: flex;
  justify-content: space-around;
  gap: 20px;

  & > * {
    width: 31%;
  }

  @include md {
    flex-wrap: wrap;

    & > * {
      flex-basis: 100%;
    }
  }
}

.btn_wrapper {
  margin: 40px 0px 60px;
  display: flex;
  justify-content: center;

  @include xs {
    a,
    button {
      width: 100%;
    }
  }
}
