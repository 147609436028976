@import 'assets/style/helper.scss';

.header {
  background-color: rgba($black, 0.5);
  z-index: 10;
}

.header_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav {
  display: flex;
  align-items: center;
  gap: 40px;

  @include tablet {
    display: none;
  }
}

.nav_item {
  position: relative;
  font-size: 16px;
  line-height: 19px;
  padding: 18px 10px;
  color: $white !important;

  &::after {
    position: absolute;
    content: '';
    inset: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(75, 116, 159, 0.15) 95.71%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  @include hover {
    &::after {
      opacity: 1;
    }
  }
}

.cart_wrapper {
  width: 175px;
  display: flex;
  justify-content: flex-end;
}

.cart_btn {
  position: relative;
  width: 36px;
  height: 36px;
  background-color: rgba($white, 0.1);
  border-radius: 50%;
  padding: 5px;
  @include flexCenter;
  transition: background-color 0.5s;

  @include hover {
    background-color: rgba($white, 0.2);
  }

  @include tablet {
    display: none;
  }

  .mark {
    position: absolute;
    top: 0;
    right: 0;
    width: 10px;
    height: 10px;
    background-color: $white_lilac;
    border-radius: 50%;
  }
}

.burger_btn {
  display: none !important;

  @include tablet {
    display: flex !important;
  }
}
