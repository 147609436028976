@import 'assets/style/helper.scss';

.card {
  padding: 40px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(90deg, rgba(16, 28, 38, 0.9) 0%, rgba(66, 79, 97, 0.9) 100%);
  background-size: calc(100% - 150px);
  background-repeat: no-repeat;

  @include tablet {
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0px;
    background-size: 100% calc(100% - 150px);
    background-position: top !important;
  }
}

.left {
  background-position: right;
}

.right {
  .image_wrapper {
    order: 2;
  }
}

.image_wrapper {
  flex-shrink: 0;
  max-width: 300px;
  width: 100%;
  height: 400px;
  box-shadow: 0px 4px 14px 5px rgba($black, 0.15);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include tablet {
    order: 2;
  }
}

.desc_wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  color: $white_water;
  font-size: 18px;
  line-height: 21px;

  @include tablet {
    order: 1;
    padding: 20px;
  }
}
