@import 'assets/style/helper.scss';

.payment_wrapper {
  position: sticky;
  top: 10px;
  width: 35%;
  border: 1px dotted rgba($blue_aztec, 0.2);
  text-align: center;
  padding: 20px;
  border-radius: 6px;
  background-color: $white;

  @include pc {
    width: 100%;
  }
}

.total_price {
  text-align: right;
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px dotted rgba($black, 0.3);
}

.actions {
  a,
  button {
    width: 100%;
  }
}
