@import 'assets/style/helper.scss';

.input_wrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.input_block {
  position: relative;
  box-shadow: 0px 4px 4px rgba($black, 0.05);
  border-radius: 10px;

  & > svg {
    position: absolute;
    top: calc(50% - 9px);
    left: 8px;
    width: 18px;
    height: 18px;

    path {
      fill: $blue_aztec;
    }
  }
}

.input {
  width: 100%;
  font-weight: 400;
  font-size: 15px;
  line-height: 17px;
  border: 1px solid;
  transition: all 0.3s ease;
  resize: none;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  &[type='number'] {
    appearance: 'textfield';
  }

  @include scrollbar;

  &:disabled {
    opacity: 0.6;
  }
}

//theme
.whiteTrans {
  color: $white_water;
  border-color: $white_water;
  border-radius: 0px;
  padding: 10px;
  background-color: transparent;

  &::placeholder {
    color: rgba($white_water, 0.5);
  }

  @include focus {
    box-shadow: 0px 0px 2px 1px $white_water;
  }
}

.blueWood {
  color: $black;
  border-color: $blue_wood;
  border-radius: 6px;
  padding: 10px;
  background-color: transparent;

  &::placeholder {
    color: rgba($black, 0.5);
  }

  @include focus {
    box-shadow: 0px 0px 2px 1px $blue_wood;
  }
}

.input_icon {
  padding-left: 30px;
}

.input_btn_wrapper {
  padding-right: 34px;
}

.input_btn {
  position: absolute;
  top: calc(50% - 10px);
  right: 8px;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: $blue_aztec;
      transition: fill 0.3s;
    }
  }

  @include hover {
    svg {
      path {
        fill: darken($blue_aztec, 10%);
      }
    }
  }
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 2px;
  color: $black;
}

.error {
  margin-top: 2px;
  padding-left: 2px;
  padding-right: 2px;
  font-weight: 700;
  font-size: 11px;
  line-height: 12px;
  color: $red;
}
