.layout {
  height: 100vh;
  display: flex;
  flex-direction: column;

  & > *:nth-child(1),
  & > *:nth-child(3) {
    flex-shrink: 0;
  }
}

.main {
  flex: 1 0 auto;
}
