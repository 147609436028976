@import 'assets/style/helper.scss';

.form {
  text-align: center;
}

.title {
  font-size: 30px;
  margin-bottom: 20px;
}

.inputs_wrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.actions {
  margin-top: 30px;
  margin-bottom: 10px;

  button {
    width: 100%;
  }
}
