@import 'assets/style/helper.scss';

.card {
  display: block;
  max-width: 300px;
  width: 100%;

  @include hover {
    .img_wrapper {
      img {
        transform: scale(1.05);
      }
    }
  }
}

.img_wrapper {
  width: 100%;
  height: 300px;
  margin-bottom: 12px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s;
  }
}

.name {
  font-size: 20px;
  line-height: 24px;
  color: $blue_wood;
  margin-bottom: 12px;
  @include threeDotsLine(2);
}

.text {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
  @include threeDotsLine(3);
}

.read_more {
  font-size: 15px;
  line-height: 16px;
  color: $blue_wood;

  @include hoverUnderline;
}
