@import 'assets/style/helper.scss';

.text {
  font-size: 22px;
  text-align: center;
  max-width: 900px;
  margin: 0 auto 40px;
}

.actions {
  display: flex;
  justify-content: center;

  @include xs {
    button {
      width: 100%;
    }
  }
}
