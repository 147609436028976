@import 'assets/style/helper.scss';

.cart {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px;

  @include pc {
    flex-wrap: wrap;
  }
}

.goods {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @include pc {
    width: 100%;
  }
}

.empty {
  padding-top: 60px;
  min-height: 300px;
  @include flexCenter;
  flex-direction: column;

  & > svg {
    width: 120px;
    height: 120px;
    margin-bottom: 30px;

    path {
      fill: rgba($black, 0.3);
    }
  }

  &_text {
    font-size: 24px;
    font-weight: 500;
    color: rgba($black, 0.4);
    margin-bottom: 10px;
  }

  &_sub_text {
    font-size: 20px;
    color: rgba($black, 0.4);
    margin-bottom: 30px;
  }
}
