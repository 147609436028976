html,
body,
p,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 500;
}

ul {
  list-style: none;
}

button,
input,
select,
textarea {
  margin: 0;
  border: none;
  outline: none;
}

button {
  font-family: inherit;
  background-color: transparent;
  padding: 0;

  &:hover {
    outline: none;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

img,
video {
  height: auto;
  max-width: 100%;
}

a {
  font-family: inherit;
  font-style: normal;
  text-decoration: none;
  outline: none;

  &:active {
    color: inherit;
  }

  &:link {
    color: inherit;
  }

  &:visited {
    color: inherit;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}
