@import 'assets/style/helper.scss';

.intro {
  position: relative;
  top: -55px;
}

.parallax {
  height: 600px;
  @include flexCenter;
  flex-direction: column;
}

.parallax_content {
  margin-top: 150px;
  @include flexCenter;
  flex-direction: column;
  z-index: 2;
}

.hint {
  height: 26px;
  text-align: center;
  background-color: $black;
  padding: 4px 0px;
  color: $blue_wood;
  font-size: 14px;
  @include threeDots;
}
