@import 'assets/style/helper.scss';

.block {
  background: linear-gradient(90deg, #101c26 0%, #4b749f 138.19%);
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.25);
  padding: 32px 0px 40px;
  margin-bottom: 30px;
  text-align: center;
  color: $white_water;
}

.title {
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.text {
  font-size: 14px;
  line-height: 16px;
  max-width: 680px;
  margin: 0px auto 20px;
}

.form {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  button {
    margin-top: 6px;
  }
}
