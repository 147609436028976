@import 'assets/style/helper.scss';

.arrow {
  position: absolute;
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: 0.6;
  z-index: 10;
  transition: opacity 0.2s ease-in-out;

  @include hover {
    opacity: 1;
  }
}

.rotate {
  transform: rotate(180deg);
}
