@import 'assets/style/helper.scss';

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page_number {
  a {
    position: relative;
    color: $black;
    padding: 3px 8px;
    margin: 0px 7px;
    font-size: 21px;
    line-height: 25px;
    cursor: pointer;
  }
}

.active_page {
  a {
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: calc(100% + 10px);
      top: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.5) 0%, rgba(75, 116, 159, 0.5) 95.71%);
      z-index: -1;
    }
  }
}

.disabled_btn {
  display: none;
}
