@import 'assets/style/helper.scss';

.nav_item {
  position: relative;
  font-size: 16px;
  line-height: 19px;
  padding: 18px 10px;
  cursor: pointer;
  color: $white !important;

  &::after {
    position: absolute;
    content: '';
    inset: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(75, 116, 159, 0.15) 95.71%);
    opacity: 0;
    transition: opacity 0.3s;
  }

  @include hover {
    &::after {
      opacity: 1;
    }

    .nav_menu {
      display: flex;
    }
  }
}

.nav_menu {
  position: absolute;
  display: none;
  flex-direction: column;
  top: 55px;
  left: 0px;
  width: 200px;
  background-color: rgba($black, 0.6);
}

.sub_item {
  font-size: 13px;
  width: 100%;
  padding: 4px;

  @include hover {
    background-color: rgba($black, 0.5);
  }
}
