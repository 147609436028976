@import 'assets/style/helper.scss';

.card {
  width: 250px;
  margin: 0 auto;
  text-align: center;
  background: $white_water;
  box-shadow: 0px 5px 5px rgba($black, 0.05);
}

.img_wrapper {
  width: 100%;
  height: 400px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.desc {
  position: relative;
  padding: 44px 14px 14px;
}

.initials {
  position: absolute;
  top: -35px;
  left: calc(50% - 35px);
  @include flexCenter;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: linear-gradient(90deg, #3b5974 0%, #4b749f 100%);
  color: $white_lilac;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 28px;
}

.name {
  text-transform: capitalize;
  font-size: 14px;
  line-height: 12px;
  margin-bottom: 10px;
}

.feedback {
  font-size: 11px;
  line-height: 12px;
  margin-bottom: 10px;
}
