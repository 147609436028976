@import 'assets/style/helper.scss';

.loader_overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background-color: rgba($black, 0.5);
  z-index: 9999;
  animation: fade-in 0.3s ease-in;
}

.loader_wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

//loader clock
.clock_loader {
  position: relative;
  border-radius: 50%;
  box-shadow: inset 0px 0px 10px 2px rgba($white_water, 1);
  border: 4px solid $white_water;
  width: 180px;
  height: 180px;

  &::after {
    content: '';
    position: absolute;
    background-color: $white_water;
    top: 5px;
    left: 85px;
    height: 80px;
    width: 4px;
    border-radius: 5px;
    transform-origin: 50% 101%;
    animation: grdAiguille 3s linear infinite;
  }

  &::before {
    content: '';
    position: absolute;
    background-color: $white_water;
    top: 35px;
    left: 85px;
    height: 50px;
    width: 4px;
    border-radius: 5px;
    transform-origin: 50% 101%;
    animation: ptAiguille 12s linear infinite;
  }

  .dot_center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $white_water;
  }
}

@keyframes ptAiguille {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes grdAiguille {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
