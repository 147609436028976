@import 'assets/style/helper.scss';

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  line-height: 17px;
  box-shadow: 0px 4px 4px rgba($black, 0.05);
  color: $white;
  text-transform: capitalize;
  overflow: hidden;
  z-index: 3;
  transition: all 0.3s ease;

  svg {
    width: 17px;
    height: 17px;

    path {
      fill: $white;
    }
  }

  &::after,
  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
  }
}

// size
.md {
  padding: 10px 16px;
  font-size: 14px;
  min-width: 120px;
}

.lg {
  min-width: 200px;
  padding: 20px;
  font-size: 20px;
}

//round
.oval {
  border-radius: 25px;
}

.block {
  border-radius: 0px;
}

// theme
.blueBlack {
  color: $white;

  &::after {
    background: linear-gradient(90deg, #101c26 0%, #4b749f 100%);
    z-index: -1;
  }

  &::before {
    background: #101c26;
    z-index: -2;
  }

  @include hover {
    &::after {
      transform: translateX(102%);
    }
  }
}

.white {
  color: $black;

  &::after {
    background: $black;
    opacity: 0;
    z-index: -1;
  }

  &::before {
    background: transparent;
    z-index: -2;
  }

  @include hover {
    color: $white;

    &::after {
      opacity: 1;
    }
  }
}

.whiteBlue {
  color: $blue_wood;
  border: 1px solid;
  border-color: $white_water;
  background-color: $white_water;

  @include hover {
    color: $white;
    background-color: $blue_aztec;
  }
}

.lightBlue {
  color: $blue_wood;

  &::after {
    background: linear-gradient(90deg, rgba(59, 89, 116, 0.05) 0%, rgba(75, 116, 159, 0.05) 100%);
    z-index: -2;
  }

  &::before {
    background: linear-gradient(90deg, #101c26 0%, #4b749f 100%);
    z-index: -1;
    transform: translateX(-102%);
  }

  @include hover {
    color: $white_lilac;

    &::before {
      transform: translateX(0);
    }
  }
}

.whiteOutline {
  color: $blue_wood;
  border: 1px solid $blue_wood;
  background-color: $white_water;

  @include hover {
    background-color: darken($white_water, 5%);
  }
}

.grayTransparent {
  color: $white;

  &::after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 31.45%, rgba(0, 0, 0, 0) 100%);
    z-index: -1;
  }

  &::before {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 40%, rgba(0, 0, 0, 0) 100%);
    z-index: -2;
    transform: translateY(-102%);
  }

  @include hover {
    &::before {
      transform: translateY(0);
    }
  }
}

.lightBlueSimple {
  background-color: #599fb5;
  color: $white;

  @include hover {
    background-color: darken(#599fb5, 8%);
  }
}

.disabled {
  opacity: 0.5;
  background-color: $black;
  cursor: default !important;

  @include hover {
    background-color: $black;
  }
}
