@import 'assets/style/helper.scss';

.modal {
  width: calc(100% - 20px) !important;
  margin: 10px !important;
  border-radius: 10px !important;
  background-color: $white !important;
}

.modal_overlay {
  background-color: rgba($black, 0.6);
}

.modal_closeBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  top: 8px !important;
  right: 8px !important;
  transition: background-color 0.3s;

  svg {
    path {
      fill: rgba($black, 0.6) !important;
    }
  }

  @include hover {
    background-color: rgba($black, 0.3);
  }
}

// themes
.modal_light {
  background-color: $white !important;

  .modal_closeBtn {
    svg {
      path {
        fill: rgba($black, 0.6) !important;
      }
    }

    @include hover {
      background-color: rgba($black, 0.1);
    }
  }
}

// sizes
.xs {
  @extend .modal;
  max-width: 400px !important;
  padding: 30px 30px 20px !important;

  @include tablet {
    padding: 30px 15px 20px !important;
  }
}

.sm {
  @extend .modal;
  max-width: 550px !important;
  padding: 30px 30px 20px !important;

  @include tablet {
    padding: 30px 15px 20px !important;
  }
}

.md {
  @extend .modal;
  max-width: 800px !important;
  padding: 24px 40px !important;

  @include tablet {
    padding: 26px 15px 20px !important;
  }
}

.th {
  @extend .modal;
  max-width: 1000px !important;
  padding: 24px 40px !important;

  @include tablet {
    padding: 26px 15px 20px !important;
  }
}

.lg {
  @extend .modal;
  max-width: 1440px !important;
  width: calc(100vw - 40px) !important;
  padding: 0px !important;
  height: calc(100vh - 60px);

  @include sm {
    width: calc(100vw - 10px) !important;
    margin: 1.2rem 5px !important;
  }
}
