@import 'assets/style/helper.scss';

.scroll_to_top {
  position: fixed;
  height: 0;
  width: 0;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  border: 1px solid rgba($black, 0.5);
  background-color: $white;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 1px rgba($black, 0.5);
  transition: all 0.3s;

  svg {
    transform: rotate(180deg) translateY(2px);

    path {
      fill: rgba($black, 0.7);
    }
  }
}

.show {
  width: 30px;
  height: 30px;
  opacity: 0.3;
  transition: all 0.3s;

  @include hover {
    width: 40px;
    height: 40px;
    opacity: 1;
  }
}
