@import 'assets/style/helper.scss';

.card {
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
  text-align: center;

  @include hover {
    .img_wrapper {
      img {
        transform: scale(1.03);
      }
    }
  }
}

.img_wrapper {
  width: 100%;
  height: 300px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-out;
  }
}

.discount {
  background-color: $black;
  padding: 3px 6px;
  color: $blue_wood;
  font-size: 16px;
  line-height: 19px;
  @include threeDots;
}

.label {
  padding: 4px;
  font-size: 15px;
  text-transform: uppercase;
  color: $black;
}

.price_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.price {
  font-size: 16px;
  line-height: 19px;
  color: $blue_wood;
}

.old_price {
  font-size: 13px;
  line-height: 16px;
  color: $black;
  text-decoration: line-through;
}

.cart_mark {
  position: absolute;
  top: 16px;
  right: 0;
  @include flexCenter;
  width: 54px;
  height: 30px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0%, rgba(66, 79, 97, 0.5) 100%);
  box-shadow: 0px 4px 4px rgba($black, 0.05);
  border-radius: 20px 0 0 20px;
}
