@import 'assets/style/helper.scss';

.footer {
  margin-top: 80px;
  padding: 16px 0px 14px;
  background-color: $black;

  @include tablet {
    margin-top: 50px;
  }
}

.footer_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @include md {
    flex-wrap: wrap;
    gap: 20px;
  }
}

.info {
  max-width: 300px;
  width: 100%;

  @include md {
    min-width: 100%;
  }
}

.info_text {
  margin-top: 6px;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: -0.06em;
  color: rgba($white, 0.5);
}

.nav {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;

  @include md {
    width: 100%;
  }

  @include tablet {
    flex-wrap: wrap;
    gap: 30px;
  }
}

.nav_item {
  width: fit-content;
  color: $white !important;
  font-size: 13px;
  line-height: 14px;
  transition: color 0.3s;

  @include hoverUnderline;

  @include hover {
    color: $blue_wood !important;
  }

  @include tablet {
    font-size: 16px;
    flex-basis: 100%;
  }
}

.contacts {
  width: 300px;

  @include md {
    width: 100%;
  }
}

.social {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 50px;

  @include md {
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  @include hover {
    svg {
      path {
        fill: $blue_wood;
      }
    }
  }
}

.contact_item {
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
  color: $white;
  font-size: 13px;
  margin-bottom: 10px;

  @include md {
    text-align: left;
    align-items: flex-start;
  }
}

.footer_bottom {
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  color: $blue_wood;
}
